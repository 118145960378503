<template>
  <ListComponent title="Invoice">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="pl-0 pr-2 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchInvoice"
          ></v-text-field>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-on="on"
                v-bind="attrs"
                @click="showFiltersDialog = true"
                class="mx-2"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(filters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <span v-if="Object.keys(filters).length == 0">Filters</span>

            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
              <v-col cols="12">
                <span
                  v-for="(value, key) in filters"
                  :key="key"
                  class="text-capitalize"
                  >{{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>

          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
        </v-col>

        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-tabs height="35" v-model="tab" centered>
            <v-tabs-slider></v-tabs-slider>
            <v-tab>
              In Progress
              <v-badge
                v-if="totalInvoiceStatistics.inprogress_invoice"
                top
                :content="totalInvoiceStatistics.inprogress_invoice"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>
              Pending
              <v-badge
                v-if="totalInvoiceStatistics.pending_invoice"
                top
                :content="totalInvoiceStatistics.pending_invoice"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>
              Accepted
              <v-badge
                v-if="totalInvoiceStatistics.accepted_invoice"
                top
                :content="totalInvoiceStatistics.accepted_invoice"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
            <v-tab>
              Rejected
              <v-badge
                v-if="totalInvoiceStatistics.rejected_invoice"
                top
                :content="totalInvoiceStatistics.rejected_invoice"
                overlap
                class="mb-4 ml-3"
              >
              </v-badge>
            </v-tab>
          </v-tabs>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="4"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            @columnMoved="resizeGrid"
            @columnVisible="resizeGrid"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allInvoiceList"
          ></AgGridVue>
        </v-col>
      </v-row>

      <DetailsParent v-model="invoiceLogDetailsDrawer" :resizable="true">
        <template #details>
          <v-card elevation="0">
            <v-card-title class="heading">
              <span class="text-h6">Invoice Log</span>
            </v-card-title>

            <v-card-text>
              <v-row class="Order-Log-Scrolling-Class mt-3">
                <v-col cols="12">
                  <span
                    class="py-12"
                    v-if="invoice_logs && invoice_logs.length == 0"
                  >
                    No logs to show!
                  </span>

                  <v-timeline dense clipped v-else>
                    <v-timeline-item
                      v-for="(log, i) in invoice_logs"
                      :key="i"
                      icon-color="primary"
                      small
                    >
                      <v-card class="elevation-2">
                        <v-card-text>
                          <table class="Full-Width">
                            <tbody>
                              <tr>
                                <th class="text-left">{{ log.updated_by }}</th>
                                <td class="text-caption text-right">
                                  {{ log.created | formatDate(true) }}
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">{{ log.message }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </DetailsParent>
    </template>

    <template #dialogs>
      <v-dialog v-model="showDownloadPreview">
        <v-card>
          <v-card-title class="secondary white--text pa-2">
            <span>Download Preview</span>

            <v-spacer></v-spacer>

            <v-btn
              class="mr-12"
              color="primary"
              depressed
              @click="invoiceDownload"
            >
              Download Invoice
            </v-btn>

            <v-btn icon @click="closeDownloadPreview">
              <v-icon class="white--text">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text v-if="downloadingInvoice" class="pt-4 Height-Class">
            <InvoiceTemplate :invoiceDetails="downloadingInvoice" />
          </v-card-text>
        </v-card>
      </v-dialog>
      <RemarksDialog
        v-model="showRemarksForm"
        :formDetails="updatingInvoice"
        :formErrors="formErrors"
        fieldKey="customer_remarks"
        :label="updatingInvoice.status + ' Remarks *'"
        @submitRemarks="updateInvoiceStatus"
      />
      <AdminCustomerInvoiceFilters
        :bindingObject="filters"
        v-model="showFiltersDialog"
        @resetFilters="resetFilters"
        @applyFilters="itemsPerPageChanged(20)"
      />
      <EmailForm
        v-model="showEmailForm"
        :invoiceId="invoiceId"
        :companyEmail="companyEmail"
      />
    </template>

    <template #actions>
      <v-btn
        v-if="permissions && permissions.add"
        @click="addMiscellaneousInvoice"
        color="primary"
        depressed
        class="mr-1"
      >
        <v-icon>mdi-plus</v-icon>
        <span>Create Misc Invoice</span>
      </v-btn>
      <v-btn
        v-if="permissions && permissions.add"
        @click="addInvoice"
        color="primary"
        depressed
      >
        <v-icon>mdi-plus</v-icon>
        <span>Create Invoice</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import RemarksDialog from "@/components/ModuleBased/dialogs/RemarksDialog.vue";
import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import AdminCustomerInvoiceButton from "@/components/AgGridComponents/AdminCustomerInvoiceButton.vue";
import Pagination from "@/components/General/Pagination.vue";
import InvoiceTemplate from "@/components/General/InvoiceTemplate.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import EmailForm from "@/components/ModuleBased/dialogForms/EmailForm.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";
import AdminCustomerInvoiceFilters from "@/components/ModuleBased/dialogs/filters/AdminCustomerInvoiceFilters.vue";

export default {
  components: {
    ListComponent,
    Columns,
    AgGridVue,
    AdminCustomerInvoiceButton,
    agColumnHeader: CustomHeader,
    Pagination,
    InvoiceTemplate,
    EmailForm,
    AdminCustomerInvoiceFilters,
    RemarksDialog,
    DetailsParent,
  },
  watch: {
    tab() {
      this.pageNo = 1;
      this.getCustomerInvoiceList();
      this.invoiceLogDetailsDrawer = false;
    },
  },
  data() {
    return {
      showRemarksForm: false,
      updatingInvoice: {},
      formErrors: {},

      sorting: {},
      headers: [
        {
          headerName: "Invoice No",
          field: "invoice_no",
          sortable: true,
          minWidth: 140,
        },
        {
          headerName: "Invoice Date",
          field: "invoice_date",
          sortable: true,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          minWidth: 110,
        },

        {
          headerName: "Customer Name",
          field: "company_name",
          sortable: true,
          minWidth: 250,
        },
        {
          headerName: `Total Amount (${this.$globals.currency})`,
          field: "total_amount",
          sortable: true,
          minWidth: 140,
        },
        {
          headerName: `Total Tax Amount (${this.$globals.currency})`,
          field: "total_tax_amount",
          sortable: true,
          minWidth: 150,
        },
        {
          headerName: `Gross Amount  (${this.$globals.currency})`,
          field: "total_gross_amount",
          sortable: true,
          minWidth: 150,
        },
        {
          headerName: "Invoice Type",
          field: "invoice_type",
          sortable: true,
          minWidth: 120,
        },
        { headerName: "BOE", field: "boe", sortable: true, minWidth: 120 },
        { headerName: "PO", field: "po_no", minWidth: 120 },
        {
          headerName: "Job Type",
          field: "job_type",
          sortable: true,
          minWidth: 90,
          valueFormatter: (params) => {
            if (params.data.invoice_type == "Miscellaneous") {
              return "MISC";
            }
            return params.value;
          },
        },
        {
          headerName: "Total DAs",
          field: "total_invoice_das",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "AdminCustomerInvoiceButton",
          pinned: "right",
          minWidth: 310,
        },
      ],
      permissions: null,

      // details vars
      selectedInvoiceId: null,
      showDetails: false,

      // Pagination Vars
      showFiltersDialog: false,
      tab: null,
      itemsPerPage: 20,
      pageNo: 1,
      totalItems: 0,
      filters: {},
      showEmailForm: false,
      invoiceLogDetailsDrawer: false,
      invoice_logs: [],

      // Ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      allInvoiceList: [],
      daList: null,

      // download vars
      showDownloadPreview: false,
      downloadingInvoice: null,
      invoiceId: null,
      companyEmail: "",
      searchInvoiceStatisticTimeout: null,
      totalInvoiceStatistics: [],
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },

  methods: {
    viewRevenueCostCharges(id) {
      let path = `/app/admin/invoice/customer/detailView/${id}`;
      this.$router.push({
        path: path,
        query: {
          active_: this.tab,
        },
      });
    },
    viewInvoiceLogs(invoiceId) {
      this.invoiceLogDetailsDrawer = true;
      this.$bus.$emit("showLoader", false);
      this.$api.customerInvoice
        .getCustomerInvoiceLogs(invoiceId)
        .then((res) => {
          this.invoice_logs = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't fetch invoice logs!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
    getTotalInvoiceCount(params) {
      if (this.searchInvoiceStatisticTimeout) {
        clearTimeout(this.searchInvoiceStatisticTimeout);
      }
      this.searchInvoiceStatisticTimeout = setTimeout(() => {
        params = { ...this.filters };
        delete params.limit;
        delete params.offset;
        delete params.statuses;
        this.$api.customerInvoice
          .getInvoiceListStatistics(params)
          .then((res) => {
            this.totalInvoiceStatistics = res.data;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't  Invoice!",
              color: "error",
            });
          });
      }, 1000);
    },

    updateInvoiceStatus() {
      this.$api.customerInvoice
        .partiallyUpdateCustomerInvoice(
          this.updatingInvoice.id,
          this.updatingInvoice
        )
        .then((res) => {
          this.getCustomerInvoiceList();
          this.$bus.$emit("showToastMessage", {
            message: "Invoice status updated successfully!",
            color: "success",
          });
          this.showRemarksForm = false;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't update invoice status!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
    openRemarksForm(contract, status) {
      this.updatingInvoice = {
        id: contract.id,
        status: status,
        customer_remarks: null,
      };
      this.showRemarksForm = true;
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    closeDownloadPreview() {
      this.downloadingInvoice = null;
      this.showDownloadPreview = false;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },

    searchInvoice() {
      this.pageNo = 1;
      this.getCustomerInvoiceList();
    },

    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getCustomerInvoiceList();
      this.getTotalInvoiceCount();
    },

    prevPage() {
      this.pageNo--;
      this.getCustomerInvoiceList();
      this.getTotalInvoiceCount();
    },

    nextPage() {
      this.pageNo++;
      this.getCustomerInvoiceList();
      this.getTotalInvoiceCount();
    },
    sendInvoiceEmail(id) {
      this.invoiceId = id;
      this.companyEmail = this.allInvoiceList.find(
        (invoice) => invoice.id == this.invoiceId
      )?.company_email;
      this.showEmailForm = true;
    },

    getCustomerInvoiceList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
        status:
          this.tab == 0
            ? "IN_PROGRESS"
            : this.tab == 1
            ? "Pending"
            : this.tab == 2
            ? "Accepted"
            : "Rejected",
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.customerInvoice
        .getCustomerInvoiceList(params)
        .then((res) => {
          this.allInvoiceList = res.data;
          this.totalItems = res.count;

          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    addMiscellaneousInvoice() {
      this.$router.push({
        path: "/app/admin/invoice/customer/create-miscellaneous",
        query: {
          active_tab: this.tab,
        },
      });
    },
    addInvoice() {
      this.$router.push({
        path: "/app/admin/invoice/customer/create",
        query: {
          active_tab: this.tab,
        },
      });
    },
    viewInvoice(id) {
      this.$router.push({
        path: `/app/admin/invoice/customer/details/${id}`,
        query: {
          active_tab: this.tab,
        },
      });
    },
    editInvoice(invoiceDetails) {
      let path = `/app/admin/invoice/customer/edit/${invoiceDetails.id}`;
      if (invoiceDetails.invoice_type == "Miscellaneous") {
        path = `/app/admin/invoice/customer/edit-miscellaneous/${invoiceDetails.id}`;
      }
      this.$router.push({
        path: path,
        query: {
          active_tab: this.tab,
        },
      });
    },
    createCreditNote(invoiceDetails) {
      let path = `/app/admin/invoice/customer/${invoiceDetails.id}/create-credit-note`;

      this.$router.push({
        path: path,
        query: {
          active_tab: this.tab,
        },
      });
    },
    checkQuery() {
      if ("active_tab" in this.$route.query) {
        this.tab = parseInt(this.$route.query.active_tab);
        this.getTotalInvoiceCount();
      }
    },
    async downloadInvoice(id, daList = null) {
      this.$bus.$emit("showLoader", true);
      this.invoiceId = id;
      this.daList = daList;
    
      await this.$api.customerInvoice
        .getCustomerInvoiceObject(id)
        .then(async (res) => {
          this.downloadingInvoice = res.data;
          this.invoiceDownload();
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    async downloadInvoiceDaExcel(data) {
      this.$bus.$emit("showLoader", true);
      await this.$api.customerInvoice
        .downloadDAExcel(data.id)
        .then(async (response) => {
          this.$bus.$emit("showLoader", false);
          var blob = response.data;

          var downloadLink = window.document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          downloadLink.download = "DaDetails" + data.invoice_no + ".xlsx";
          downloadLink.click();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Data not found"
            }`,
            color: "error",
          });

          this.$bus.$emit("showLoader", false);
        });
    },
    async invoiceDownload(params = {}) {
      params = {
        da_list: this.daList,
      };
      this.$bus.$emit("showLoader", true);
      await this.$api.customerInvoice
        .getCustomerInvoiceBlopObject(this.invoiceId, params)
        .then(async (response) => {
          this.$bus.$emit("showLoader", false);
          let blob = new Blob(["\ufeff", response.data], {
            type: "application/octet-stream",
          });
          let link = document.createElement("a");
          const objUrl = window.URL.createObjectURL(blob);
          link.href = objUrl;
          if (params.da_list == "da_list") {
            link.download =
              "DaDetails/" + this.downloadingInvoice.invoice_no + ".pdf";
          } else {
            link.download = this.downloadingInvoice.invoice_no + ".pdf";
          }
          link.click();
          window.URL.revokeObjectURL(objUrl);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    downloadGeneratedInvoice() {
      let opt = {
        margin: 0.25,
        filename: `${this.downloadingInvoice.invoice_no}.pdf`,
        pagebreak: { mode: ["legacy"] },
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 1,
          dpi: 300,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      };
      let element = document.getElementById("invoiceAttachment");
      this.$html2pdf().set(opt).from(element).save();
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("invoice");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
      }
    }
  },

  mounted() {
    this.checkQuery();
    this.getTotalInvoiceCount();
  },
};
</script>

<style scoped>
.Height-Class {
  height: calc(100vh - 160px);
  overflow-y: auto;
}
</style>
